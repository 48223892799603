import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { FadeIn } from "../../components/fade-in/fade-in";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { MstrButtonOutline } from "../../components/mstr-button-outline/mstr-button-outline";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { StaggerList } from "../../components/stagger-list/stagger-list";
import {
  ChapterEntity,
  InitiativeWithProgressOutput,
  LearnerDetailsOutput,
  RefdocCountOutput,
  UnitEntity,
} from "../../generated/graphql";
import { ReferenceLibrary } from "../../partials/reference-library/reference-library";
import "./home.scss";
import { InitiativeIntro } from "./initiative-intro/initiative-intro";
import { InitiativeItem } from "./initiative-item/initiative-item";
import { WelcomeMessage } from "./welcome/welcome";

interface ReassignInitiativeData {
  reassignInitiativeToLearner: UnitEntity[];
}

const GET_INITIATIVES = gql`
  query Section {
    getInitiativesForLearnerWithProgress {
      unit {
        id
        name
        parent_unit
        is_private
        reassigned_display_name
        intro
      }
      questionCount
      seen_intro
      progress
      isLastActiveInitiative
      assignmentDate
    }
    getReferenceDocumentsForLearner {
      id
      chapter_name
      refdocs {
        id
        document_name
        url
        reftype
      }
    }
    getRefdocViewCount {
      document_id
      count
    }
    getLearnerDetails {
      id
      first_name
      last_name
      user_id_state
      is_doctor
    }
  }
`;

const REASSIGN_INITIATIVE = gql`
  mutation ReassignInitiativeToLearner($initiative_id: Int!) {
    reassignInitiativeToLearner(initiative_id: $initiative_id) {
      id
      name
    }
  }
`;

interface LearnerInitiativesData {
  getInitiativesForLearnerWithProgress: InitiativeWithProgressOutput[];
  getLearnerDetails: LearnerDetailsOutput;
  getRefdocViewCount: RefdocCountOutput[];
  getReferenceDocumentsForLearner: [ChapterEntity];
}

export const HomeScreen = () => {
  const { loading, error, data, refetch } = useQuery<LearnerInitiativesData>(
    GET_INITIATIVES,
    {
      variables: { user_id: 1 },
      fetchPolicy: "no-cache",
    }
  );

  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const redirectLink = localStorage.getItem("redirectLink");
    if(redirectLink) {
      localStorage.removeItem("redirectLink");
      window.location.href = redirectLink;
    }
  }, []);

  // Force user to change their password
  if (data) {
    if (data.getLearnerDetails.user_id_state === "PR") {
      navigate("/password-reset");
    }
  }

  let initiatives: InitiativeWithProgressOutput[] = [];

  if (data && data.getInitiativesForLearnerWithProgress) {

    // initiatives = initiatives.filter(i => i.questionCount > 0);
    
    // Sort by assignment date. Last assigned initiative on top
    initiatives = data!.getInitiativesForLearnerWithProgress.sort((a, b) => {
      return b.assignmentDate - a.assignmentDate;
    });

    // Move last active initiative on top
    initiatives = initiatives.sort((a) => {
      return a.isLastActiveInitiative ? -1 : 1;
    });

    const completedInitiatives = initiatives!.filter((i) => i.progress >= 100);
    const inProgressInitiatives = initiatives!.filter((i) => i.progress < 100);

    // Incomplete initiatives on top, completed initiatives after that
    initiatives = [...inProgressInitiatives, ...completedInitiatives];
  }

  const recentInitiatives = initiatives.slice(0, 6);
  const otherInitiatives = initiatives.slice(6, 200);

  const [selectedInitiative, setInitiative] = useState<UnitEntity>();
  const [initiativesShown, setInitiativesShown] = useState(false);
  const [
    initiativeToReassign,
    setInitiativeToReassign,
  ] = useState<UnitEntity>();

  const [reassignInitiative] = useMutation<ReassignInitiativeData>(
    REASSIGN_INITIATIVE,
    {
      update: (cache, response) => {
        refetch();
        setInitiativeToReassign(undefined);
      },
    }
  );

  const startInitiative = (initiative: UnitEntity) => {
    navigate(`/initiative/${initiative.id}`);
  };

  const getJsxForInitiative = (
    initiativeWithProgress: InitiativeWithProgressOutput
  ) => {
    const initiative = initiativeWithProgress.unit;
    return (
      <div key={initiativeWithProgress.unit.id}>
        <InitiativeItem
          onClick={() => {
            if (initiativeWithProgress.progress >= 100) {
              setInitiativeToReassign(initiativeWithProgress.unit);
            } else {
              const key = `intro-${initiative.id}`;
              const introShown = initiativeWithProgress.seen_intro;
              if (introShown || !initiative.intro) {
                startInitiative(initiative);
              } else {
                navigate(`/initiative-intro/${initiative.id}`);
                // setInitiative(initiative);
              }
            }
          }}
          allInitiatives={initiatives}
          showProgressBar={true}
          initiative={initiative}
          progress={initiativeWithProgress.progress}
        />
      </div>
    );
  };

  if (loading) return <FullScreenLoader />;
  if (error) return <div>
    <Navigate to="/login" />
  </div>;

  const refdocCount: {[k: string]: number} = {};
  if(data?.getRefdocViewCount) {
    for(let row of data?.getRefdocViewCount) {
      refdocCount[row.document_id] = row.count || 0;
    }
  }

  let firstInitiativename = '';
  if(recentInitiatives.length) {
    const firstUnit = recentInitiatives[0].unit;
    if(firstUnit.is_private) {
      firstInitiativename = firstUnit.name.split("-")[0];
    } else {
      firstInitiativename = firstUnit.name;
    }
  }

  if (!selectedInitiative) {
    return (
      <div className="initiative-home-screen">
        <WelcomeMessage details={data?.getLearnerDetails!} />
        <div className="px-4">
        {initiativeToReassign && (
          <div className="ms-modal">
            <div className="modal-content">
              <div style={{ fontSize: 18 }}>
                Would you like to go through this initiative again?
              </div>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <MstrButton
                  width={100}
                  onClick={() => {
                    reassignInitiative({
                      variables: {
                        initiative_id: +initiativeToReassign!.id,
                      },
                    });
                  }}
                  text="Yes"
                />
                <MstrButtonOutline
                  width={100}
                  onClick={() => {
                    setInitiativeToReassign(undefined);
                  }}
                  text="No"
                />
              </div>
            </div>
          </div>
        )}

        <h1 style={{ fontSize: 16 }}>My Initiatives</h1>

        <StaggerList
          list={recentInitiatives.filter(i => i.questionCount > 0).map(getJsxForInitiative)}
          onAnimationComplete={() => setInitiativesShown(true)}
        />

        {showMore && (
          <StaggerList
            list={otherInitiatives.filter(i => i.questionCount > 0).map(getJsxForInitiative)}
            onAnimationComplete={() => setInitiativesShown(true)}
          />
        )}
        {initiativesShown &&
          recentInitiatives.length + otherInitiatives.length > 6 && (
            <div
              className="more-less-button"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "LESS ▲" : "MORE ▼"}
            </div>
          )}
        </div>
        <br />
        {initiativesShown && (
          <FadeIn>
            <ReferenceLibrary
              refetchInitiatives={async () => {
                await refetch();
              }}
              assignedInitiativeIds={initiatives.map(i => +i.unit.id)}
             refdocCount={refdocCount} firstInitiativename={firstInitiativename} data={data?.getReferenceDocumentsForLearner!} />
          </FadeIn>
        )}
      </div>
    );
  } else {
    return (
      <InitiativeIntro
        learnerDetails={data?.getLearnerDetails!}
        initiative={selectedInitiative}
      />
    );
  }
};
