import React, {useEffect, useState} from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import "./auto-login.scss";
import { useNavigate, useParams } from "react-router-dom";




interface LoginDataResponse {
  accessToken: string;
  error: boolean;
}

export const AutoLoginScreen = (props: any) => {
  const params = useParams();
  const token_str = params.id;
  const [showFields, setShowFields] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();
  const LOGIN_MUTATION = gql`
  mutation getJwtFromAuthToken($token: String!) {
    getJwtFromAuthToken(token: $token) {
      accessToken
      error
    }
  }
`;
  const [loginUser, { error, loading, data }] = useMutation<LoginDataResponse>(
      LOGIN_MUTATION
      ,
      {
        update: (cache, response: any) => {
          localStorage.setItem("token", response.data?.getJwtFromAuthToken?.accessToken!);
          navigate(`/home`);
        },
      }
  );

  useEffect(() => {
    setTimeout(() => {
      loginUser({
        variables: {
          token: token_str
        },
      }).catch((err) => {
        console.log("Got Error: ", err);
      });
    }, 2000);
  }, [])

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => setShowFields(true)}>
        Please wait while we validate your credentials.
      </TypeText>
      <br />
      {showFields && (
        <FadeIn onComplete={() => setShowButton(true)}>
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

          </div>
        </FadeIn>
      )}
      <br />
      {showSuccess && (
        <TypeText
          onComplete={() => {
            setTimeout(() => navigate("/home"), 2000);
          }}
        >
          Please wait.
        </TypeText>
      )}
    </div>
  );
};
