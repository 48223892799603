import React from 'react';
import { ConversationDetails } from './conversation-details';
import { useParams } from 'react-router-dom';

export const ConversationDetailsRouterWrapper = (props: any) => {
    const params = useParams();
    const conversation_id = params.id;

    if(conversation_id) {
        return <ConversationDetails conversation_id={+conversation_id} />
    } else {
        return null;
    }
}