import React from "react";
import "./question.screen.scss";
import { gql, useQuery } from "@apollo/client";
// import Typist from "react-typist";
// import { AnimatePresence, motion } from "framer-motion";
import { TypeText } from "../../components/type-text/type-text";
import { useParams } from "react-router-dom";

const GET_QUESTION_DETAILS = gql`
  query Question($id: Int!) {
    question(id: $id) {
      id
      content
      content_html @client
      answers {
        id
        content
        content_html @client
      }
    }
  }
`;

export const QuestionScreen = (props: any) => {
  const params = useParams();
  const questionId = +(params.id ?? "0");
  const { loading, error, data } = useQuery(GET_QUESTION_DETAILS, {
    variables: { id: questionId },
  });

  if (loading) return <div></div>;
  if (error) return <div>ERROR</div>;
  return (
    <div>
      <TypeText delay={3000} onComplete={() => {}}>
        <div
          dangerouslySetInnerHTML={{ __html: data.question.content_html }}
        ></div>
      </TypeText>
    </div>
  );
};
