import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { CustomRefdocModal } from "./custom-refdoc-modal";

export const CustomRefdocOpen = (props: any) => {
  const params = useParams();
  const document_id = +params.id!;
  const navigate = useNavigate();

  const allowedHosts = ["product.mindstrength.co", "demo.mindstrength.co", "localhost"];

  if(allowedHosts.indexOf(window.location.hostname) === -1) {
    return (
      <Navigate to="/home" />
    )
  }


  return (
    <div className="flex flex-1" style={{ background: "#cfdaf5" }}>
      <div className="max-w-xl mx-auto flex flex-1 w-full bg-white">
        <CustomRefdocModal hideClose={true} hideShare={true} closePreview={() => navigate("/home")} refDocId={document_id} />
      </div>
    </div>
  )
};
