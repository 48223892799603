import React from "react";
import { useParams } from "react-router-dom";
import { UMHeaderDarkOpenNoLogout } from "../../components/header/umheader_dark-open-no-logout";
import { InlineAssistant } from "../../partials/reference-library/ref-library-item/inline-assistant";

export const IWCEAssist = () => {
    const { token } = useParams<{ token: string }>();

    console.log("token in custom assist", token);

    localStorage.setItem("token", atob(token || ""));

    return <div className="flex flex-col flex-1 p-4">
        <div className="absolute top-0 left-0 w-full">
            <UMHeaderDarkOpenNoLogout />
        </div>
        <InlineAssistant
            assignedInitiativeIds={[]}
            refetchInitiatives={() => Promise.resolve()}
            toggle={() => { }}
        />
    </div>;
};
