import React from "react";
import { LearnerDetailsOutput } from "../../../generated/graphql";
import yogaImage from "../../../images/corporate_yoga.png";

interface Props {
  details: LearnerDetailsOutput;
}

export const WelcomeMessage = (props: Props) => {
  const { details } = props;
  let name = props.details.first_name;
  
  if(details.is_doctor) {
    name = 'Dr. ' + details.last_name;
  }

  return (
    <div className="welcome-message">
      <img alt="Welcome" src={yogaImage} />
      <div className="welcome-text">Welcome, {name}!</div>
    </div>
  );
};
