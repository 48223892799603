import { gql, useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TypeText } from "../../components/type-text/type-text";
import "./auto-login.scss";




interface LoginDataResponse {
  accessToken: string;
  error: boolean;
}

export const AutoLoginInitiativeScreen = (props: any) => {
  const params = useParams();
  const token_str = params.id;
  const initiative_id = params.initiativeid;
  
  const navigate = useNavigate();
  const LOGIN_MUTATION = gql`
  mutation getJwtFromAuthToken($token: String!) {
    getJwtFromAuthToken(token: $token) {
      accessToken
      error
    }
  }
`;
  const [loginUser, { error, loading, data }] = useMutation<LoginDataResponse>(
      LOGIN_MUTATION
      ,
      {
        update: (cache, response: any) => {
          console.log({
            response,
          });
          localStorage.setItem("token", response.data?.getJwtFromAuthToken?.accessToken!);
          navigate(`/initiative-intro/${initiative_id}`);
        },
      }
  );

  useEffect(() => {
    setTimeout(() => {
      loginUser({
        variables: {
          token: token_str
        },
      }).catch((err) => {
        console.log("Got Error: ", err);
      });
    }, 2000);
  }, [])

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => {}}>
        Please wait while we validate your credentials.
      </TypeText>
      <br />
      <br />
      
    </div>
  );
};
