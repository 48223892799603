import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import "./forgot-password.scss";
import email_image from "../../images/email.png";
import { useNavigate } from "react-router-dom";

const UPDATE_LEARNER_PASSWORD = gql`
  mutation ForgotLearnerPassword($email: String!) {
    forgotLearnerPassword(email: $email) {
      error
    }
  }
`;

interface UpdatePasswordData {
  forgotLearnerPassword: {
    error: boolean;
  };
}

export const ForgotPasswordScreen = () => {
  const [showFields, setShowFields] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [forgotError, setForgotError] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);

  const navigate = useNavigate();

  const [changePassword] = useMutation<UpdatePasswordData>(
    UPDATE_LEARNER_PASSWORD,
    {
      update: (cache, response) => {
        setShowLoading(false);
        console.log("Got forgot password: ", response);
        if (response.data?.forgotLearnerPassword.error) {
          setForgotError(true);
        } else {
          setForgotSuccess(true);
        }
        // navigate("/home");
      },
    }
  );

  const updatePassword = () => {
    setShowLoading(true);
    setForgotError(false);
    setForgotSuccess(false);
    changePassword({
      variables: {
        email: password,
      },
    });
  };

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => setShowFields(true)}>
        We will need your email address to send you a new password.
      </TypeText>
      <br />
      {showFields && (
        <FadeIn onComplete={() => setShowButton(true)}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ height: 40, paddingRight: 10 }} src={email_image} />
            <input
              value={password}
              placeholder="Enter your email here"
              onChange={(e) => setPassword(e.target.value)}
              className="text-password"
              type="text"
            />
          </div>
        </FadeIn>
      )}
      <br />
      {showButton && (
        <FadeIn>
          <MstrButton
            onClick={updatePassword}
            width={250}
            showLoading={showLoading}
            text="Reset password"
          ></MstrButton>
        </FadeIn>
      )}

      <br />
      {forgotSuccess && (
        <TypeText
          onComplete={() => {
            setTimeout(() => {
              navigate("/home");
            }, 3000);
          }}
        >
          Reset password instructions have been sent by email. Please go through
          them to reset your password.
        </TypeText>
      )}

      {forgotError && (
        <TypeText onComplete={() => {}}>
          Seems like we were not able to find a user with the given email.
          Please check again.
        </TypeText>
      )}
    </div>
  );
};
