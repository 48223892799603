import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomRefdocModal } from "./custom-refdoc-modal";

export const CustomRefdoc = (props: any) => {
  const params = useParams();
  const document_id = +params.id!;
  const navigate = useNavigate();

  return <CustomRefdocModal closePreview={() => navigate("/home") } refDocId={document_id}  />
};
