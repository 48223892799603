import { gql, useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FadeIn } from "../../components/fade-in/fade-in";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { MessageWithExclamation } from "../../components/section-go-back-warning/section-go-back-warning";
import { SectionStartConnector } from "../../components/section-start-connector/section-start-connector";
import { eventBus } from "../../event-bus/event-bus";
import { LearnerDetailsOutput, SectionEntity } from "../../generated/graphql";
import { SectionContentPartial } from "../../partials/section-content/section-content.partial";
import { SectionQuestions } from "../section-questions/section-questions";
import "./section.screen.scss";
import { ClosingSequence } from "../../components/closing-sequence/closing-sequence";

const GET_SECTION_DETAILS = gql`
  query Section($id: Int!, $initiative_id: Int!) {
    section(id: $id) {
      id
      section_header
      content
      content_html @client
      referencedocs
    }
    isInitiativeCompleted(initiative_id: $initiative_id)
    getLearnerDetails {
      first_name
      last_name
      is_doctor
    }
  }
`;

interface Props {
  sectionId: Number;
  initiativeId: Number;
}

interface NextSectionData {
  section: SectionEntity;
  getLearnerDetails: LearnerDetailsOutput;
  isInitiativeCompleted: boolean;
}

let removeVideoListeners: any;

export const SectionScreen: React.FunctionComponent = (props) => {
  const params = useParams();
  const sectionId = +params.sectionid!;
  const initiativeId = +params.initiativeid!;
  const sortid = +params.sortid!;
  const { loading, error, data: sectionData } = useQuery<NextSectionData>(
    GET_SECTION_DETAILS,
    {
      variables: { id: sectionId, initiative_id: initiativeId },
    }
  );

  const [sectionStartTime, setSectionStartTime] = useState(new Date());
  const [sectionHasVideo, setSectionHasVideo] = useState(false);
  const [videoWatchedDuration, setVideoWatchedDuration] = useState(0);
  const [videoTotalDuration, setVideoTotalDuration] = useState(0);

  const [showQuestionsLoader, setShowQuestionsLoader] = useState(false);

  const [showQuestions, setShowQuestions] = useState(false);
  const [isSectionShown, setShowSection] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [showBackWarning, setShowBackWarning] = useState(false);

  const [paused, setPaused] = useState(false);

  useEffect(() => {
    setPaused(false);
    setUserListenedToElementSpeech(false);
  }, [sectionData])

  const [autoScrollDisabled, setAutoScrollDisabled] = useState(false);
  const [userListenedToElementSpeech, setUserListenedToElementSpeech] = useState(false);

  const removeSubscription = () => {
    eventBus.remove("DISABLE_AUTO_SCROLL", disableAutoScroll);
    eventBus.remove("ENABLE_AUTO_SCROLL", enableAutoScroll);
  };

  const disableAutoScroll = () => {
    setAutoScrollDisabled(true);
  }

  const enableAutoScroll = () => {
    setAutoScrollDisabled(false);
  }

  useEffect(() => {
    eventBus.on("DISABLE_AUTO_SCROLL", disableAutoScroll);
    eventBus.on("ENABLE_AUTO_SCROLL", enableAutoScroll);
    return removeSubscription;
  }, []);

  const doesSectionHaveVideos = () => {
    const content = sectionData?.section.content;
    if (content && content.blocks) {
      const blocks = content.blocks;
      return blocks.filter((b: any) => b.type === "video").length > 0;
    } else {
      return false;
    }
  };

  const showSection = () => {
    setShowSection(true);
    setSectionStartTime(new Date());
    setTimeout(() => {
      if (sectionData && sectionData.section) {
        const content = sectionData.section.content;
        if (content && content.blocks) {
          const blocks = content.blocks;
          const sectionHasVideo =
            blocks.filter((b: any) => {
              return b.type === "video" && b.data && b.data.autoplay !== true;
            }).length > 0;

          if (!sectionHasVideo) {
            setShowBackWarning(true);
            setShowContinueButton(true);
          } else {
            setSectionHasVideo(true);
            const firstVideo = document.querySelector(".section-content video");
            let isShowButtonCalled = false;
            if (firstVideo) {
              const showContinueAndCancelListeners = () => {
                setShowBackWarning(true);
                setShowContinueButton(true);
              };

              removeVideoListeners = () => {
                firstVideo.removeEventListener("timeupdate", onVideoProgress);
                firstVideo.removeEventListener("pause", onVideoPause);
              };

              const onVideoProgress = (a: any) => {
                setPaused(true);
                const duration = (firstVideo as any).duration;
                const currentTime = (firstVideo as any).currentTime;

                setVideoWatchedDuration(parseInt(currentTime));
                setVideoTotalDuration(duration);

                if (currentTime > duration - 3) {
                  setShowBackWarning(true);
                  showContinueAndCancelListeners();
                }
              };

              firstVideo.addEventListener("timeupdate", onVideoProgress);
              const onVideoPause = () => {
                setPaused(false);
                showContinueAndCancelListeners();
              };
              firstVideo.addEventListener("pause", onVideoPause);
            } else {
            }
          }
        }
      }
    }, 2000);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setShowSection(false);
    setShowQuestions(false);
    setSectionHasVideo(false);
    setVideoWatchedDuration(0);
    setVideoTotalDuration(0);
    setShowContinueButton(false);
    setShowBackWarning(false);
  }, [sectionId]);

  // useEffect(() => {

  // }, [sectionData]);

  if (loading) return <FullScreenLoader />;
  if (error) return <div>ERROR</div>;

  if(sectionData?.isInitiativeCompleted) {
    return <div className="p-6">
      <ClosingSequence initiativeId={initiativeId} />
    </div>;
  }

  return (
    <div className="SectionScreen relative">
      <div
        className={`section-content-container px-5 ${showQuestions ? "pt-6" : ""} ${
          showQuestions && !autoScrollDisabled ? "scroll-to-end" : ""
        }`}
      >
        <div className={showQuestions ? "fade-section" : ""}>
          <SectionStartConnector
            pause={paused}
            setUserListenedToElementSpeech={setUserListenedToElementSpeech}
            sectionId={sectionData?.section.id!}
            sortid={sortid}
            section_header={sectionData?.section.section_header!}
            onComplete={() => showSection()}
          />
        </div>
        
        {isSectionShown && (
          <FadeIn duration={2}>
            <SectionContentPartial
              fade={showQuestions}
              section={sectionData?.section!}
            />
            <div className={showQuestions ? "fade-section" : ""}>
              {showBackWarning && (
                <FadeIn>
                  <MessageWithExclamation
                    message="Remember, you won't be able to return back to the section when we start
        our discussion"
                  />
                </FadeIn>
              )}
              {showContinueButton && !showQuestions && (
                <FadeIn>
                  <MstrButton
                    className={showQuestions ? "opacity-0 pointer-events-none" : ""}
                    showLoading={showQuestionsLoader}
                    disabled={showQuestionsLoader}
                    text="Continue"
                    onClick={() => {
                      setShowQuestionsLoader(true);
                      setPaused(true);
                      if(removeVideoListeners) {
                        removeVideoListeners();
                      }
                      const now = new Date();
                      if (sectionHasVideo) {
                        const watchPerc = (
                          (videoWatchedDuration / videoTotalDuration) *
                          100
                        ).toString();
                        eventBus.dispatch("USER_EVENT", {
                          event: "SECTION_ATTEMPT",
                          details: {
                            video_duration: videoTotalDuration,
                            video_watched_perc: parseInt(watchPerc),
                            attempt_time: 0,
                            unit_id: initiativeId,
                            section_id: sectionId,
                            question_id: 0,
                          },
                        });
                      } else {
                        eventBus.dispatch("USER_EVENT", {
                          event: "SECTION_ATTEMPT",
                          details: {
                            video_duration: 0,
                            video_watched_perc: 0,
                            attempt_time:
                              now.getTime() - sectionStartTime.getTime(),
                            unit_id: initiativeId,
                            section_id: sectionId,
                            question_id: 0,
                          },
                        });
                      }
                      document
                        .querySelectorAll(
                          `#mstr-section-${sectionData?.section.id} video`
                        )
                        .forEach((video: any) => video.pause());
                      setTimeout(() => {
                        setShowQuestionsLoader(false);
                        setShowQuestions(true);
                      }, 2000);
                    }}
                  />
                </FadeIn>
              )}
              <br />
              <br />
            </div>
          </FadeIn>
        )}

        {showQuestions && (
          <div className="z-10 absolute top-0 left-0 w-full p-4 text-sm bg-blue-400 text-white flex">
            <div className="flex-1">{sectionData?.section.section_header!}</div>
            <div onClick={() => {
              navigate("/home");
            }}><XIcon className="w-5 h-5" /></div>
          </div>
        )}

        {showQuestions && (
          <SectionQuestions
            userListenedToElementSpeech={userListenedToElementSpeech}
            refdocs={sectionData?.section.referencedocs || []}
            learnerDetails={sectionData?.getLearnerDetails!}
            sectionId={sectionId}
            initiativeId={initiativeId}
            isInitiativeAlreadyCompleted={sectionData?.isInitiativeCompleted}
          />
        )}
      </div>
    </div>
  );
};
