import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import "./set-password.scss";
import { useNavigate, useParams } from "react-router-dom";

const UPDATE_LEARNER_PASSWORD = gql`
  mutation UpdateLearnerPasswordFromResetToken(
    $token: String!
    $password: String!
  ) {
    resetPassword(token: $token, password: $password) {
      error
      message
    }
  }
`;

interface UpdatePasswordData {
  resetPassword: {
    error: boolean;
    message: string;
  };
}

interface TokenDetailsData {
  getForgotTokenDetails: string;
}

export const SetPasswordScreen = (props: any) => {
  const params = useParams();
  const token_str = params.token;
  const [showFields, setShowFields] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();

  const GET_TOKEN_DETAILS = `
    query {
      getForgotTokenDetails(token: "${token_str}")
    }
  `;

  const { data, error, loading } = useQuery<TokenDetailsData>(
    gql(GET_TOKEN_DETAILS)
  );

  const [changePassword] = useMutation<UpdatePasswordData>(
    UPDATE_LEARNER_PASSWORD,
    {
      update: (cache, response) => {
        setShowLoading(false);
        console.log("Got update password: ", response);
        setShowSuccess(true);
      },
    }
  );

  if (data) {
    if (!data.getForgotTokenDetails.length) {
      console.log("Not valid token");
      // navigate("/home");
    }
  }

  const updatePassword = () => {
    setShowLoading(true);
    changePassword({
      variables: {
        password,
        token: token_str,
      },
    });
  };

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => setShowFields(true)}>
        Hi, let's set you up with a secure password. Remember to keep it safe
        with you.
      </TypeText>
      <br />
      {showFields && (
        <FadeIn onComplete={() => setShowButton(true)}>
          {/*<div style={{ display: "flex", alignItems: "center" }}>*/}
          {/*  <img*/}
          {/*    style={{ height: 40 }}*/}
          {/*    src="https://lh3.googleusercontent.com/proxy/lBKLphBNJ09Qwaiv4H56bdPqUkdEKRsrkx749KmMZDOnHt0Sn2K9buUmd483mU7-McyG6wpnwD08A-x6NhV_oHc9YjDe9mQhQTxypCAw06U1p0eOPLQ1hqmJV4NqDEoIhvi2Jzkppgj1-rvxdVnZj_2udypm"*/}
          {/*  />*/}
          {/*  <input*/}
          {/*    value={password}*/}
          {/*    onChange={(e) => setPassword(e.target.value)}*/}
          {/*    className="text-password"*/}
          {/*    type="text"*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                      id="password"
                      name="password"
                      type="text"
                      autoComplete="off"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </form>
          </div>
        </FadeIn>
      )}
      <br />
      {showButton && !showSuccess && (
        <FadeIn>
          <MstrButton
            onClick={updatePassword}
            disabled={password.length < 6}
            width={250}
            showLoading={showLoading}
            text="Set password"
          ></MstrButton>
        </FadeIn>
      )}
      <br />
      {showSuccess && (
        <TypeText
          onComplete={() => {
            setPassword('');
            setTimeout(() => navigate("/home"), 2000);
          }}
        >
          Your password has been reset successfully.
        </TypeText>
      )}
    </div>
  );
};
