import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GetInitiativeSectionOutput, InitiativeWithProgressOutput, LearnerDetailsOutput, UnitEntity,
} from "../../generated/graphql";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { Redirect, useNavigate, useParams } from "react-router-dom";
import { InitiativeIntro } from "../home/initiative-intro/initiative-intro";

const GET_INITIATIVES = gql`
  query Section {
    getInitiativesForLearnerWithProgress {
      unit {
        id
        name
        parent_unit
        is_private
        reassigned_display_name
        intro
      }
      seen_intro
      progress
      isLastActiveInitiative
      assignmentDate
    }

    getLearnerDetails {
      id
      first_name
      last_name
      user_id_state
      is_doctor
    }
  }
`;

interface LearnerInitiativesData {
  getInitiativesForLearnerWithProgress: InitiativeWithProgressOutput[];
  getLearnerDetails: LearnerDetailsOutput;
}

export const InitiativeIntroScreen = (props: any) => {
  const params = useParams();
  const initiativeId = params.id ?? "0";
  const { loading, error, data, refetch } = useQuery<LearnerInitiativesData>(
    GET_INITIATIVES,
    {
      variables: { user_id: 1 },
      fetchPolicy: "no-cache"
    }
  );

  if(loading) {
    return <FullScreenLoader />;
  }

  const selectedInitiative: UnitEntity = data?.getInitiativesForLearnerWithProgress.filter(row => {
    return +row.unit.id === +initiativeId;
  })[0].unit!;

  return (
    <div className="flex-1" >
      <InitiativeIntro
        learnerDetails={data?.getLearnerDetails!}
        initiative={selectedInitiative}
      />
    </div>
  )
};
