import { XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../constants";
import { eventBus } from "../../event-bus/event-bus";
import { CustomRefdocModal } from "../../screens/custom-refdoc/custom-refdoc-modal";
import { QuickFlowModal } from "../../screens/quickflow/quickflow-modal";
// import { Document, Page } from "react-pdf";
import quickflow from "./quickflow.png";

import "./refdoc-item.scss";
import { RefdocPreview } from "./refdoc-preview/refdoc-preview";
import classNames from "classnames";

interface Props {
  id?: number;
  name: string;
  url: string;
  type: string;
  refdocCount?: {[k: string]: number};
}

const showCount = localStorage.getItem("showrefcount");

export const RefdocItem = (props: Props) => {
  const refdocCount = props.refdocCount || {};
  const [id] = useState(props.id || parseInt(Math.random() * 10000000000 + ""));
  let filename = props.url;
  let lx_url = "https://demo.mindstrength.co/slx";
  var file_extension_url = lx_url + "/assets/fileextension/";
  const [isDownloadable, setDownloadable] = useState(false);
  const [extension, setExtension] = useState("");

  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();

  const emitRefdocRedEvent = (id: number) => {
    eventBus.dispatch("USER_EVENT", {
      event: "REFERENCE_DOCUMENT_READ",
      details: {
        object_id: id,
        reftype: props.type
      },
    });

    // if (props.type && props.type === "QUICKFLOW") {
    //   navigate(`/quickflow/${props.id}`);
    //   return false;
    // }

    // if (props.type && props.type === "CUSTOM") {
    //   navigate(`/custom-refdoc/${props.id}`);
    //   return false;
    // }

    if (props.type === "QUICKFLOW" || props.type === "CUSTOM") {
      setShowPreview(true);
    } else {
      const newUrl =
      CONSTANTS.NEST_BACKEND_URL!.replace("graphql", "") +
      "file-upload/ref/" +
      props.id;
      window.open(newUrl, "_blank");
    }


    // if (
    //   extension != "pdf.png" &&
    //   extension != "smallimg.png" &&
    //   extension != "smallvid.png"
    // ) {
    // }
  };

  useEffect(() => {
    let fileparts = filename.split(".");
    let extensionName = fileparts[fileparts.length - 1];
    extensionName = extensionName.toLowerCase();
    let extension = "";
    if (["xlsx", "xls", "csv"].indexOf(extensionName) > -1) {
      extension = "xls.png";
      setDownloadable(true);
    } else if (
      ["mp4", "m4a", "mov", "ogg", "wmv", "webm"].indexOf(extensionName) > -1
    ) {
      if (["m4a", "ogg", "wmv", "webm"].indexOf(extensionName) > -1) {
        setDownloadable(true);
      }
      extension = "smallvid.png";
    } else if (["pdf"].indexOf(extensionName) > -1) {
      extension = "pdf.png";
      // if (this.deviceInfo.browser === 'IE') {
      //   doc.download = true;
      // }
    } else if (["doc", "docx", "odt"].indexOf(extensionName) > -1) {
      setDownloadable(true);
      extension = "doc.png";
    } else if (["mp3"].indexOf(extensionName) > -1) {
      extension = "smallaud.png";
    } else if (
      ["jpg", "png", "gif", "webp", "tiff", "jpeg"].indexOf(extensionName) > -1
    ) {
      extension = "smallimg.png";
    } else if (["ppt", "pptx"].indexOf(extensionName) > -1) {
      extension = "ppt.png";
    } else {
      extension = "file.png";
    }
    setExtension(extension);
  }, []);

  const newUrl =
    CONSTANTS.NEST_BACKEND_URL!.replace("graphql", "") +
    "file-upload/ref/" +
    props.id;

  let count = 0;
  if(props.refdocCount && props.refdocCount[props.id || 0]) {
    count = props.refdocCount[props.id || 0];
  }

  return (
    <a
      target=""
      style={{ color: "#1f7cc5" }}
    >
      {/* {showPreview && extension === "pdf.png" && (
        <RefdocPreview
          id={props.id}
          url={newUrl}
          extension={extension}
          closePreview={() => setShowPreview(false)}
        />
      )} */}

      {/* {showPreview && extension === "smallimg.png" && props.type != "QUICKFLOW" && props.type != "CUSTOM" && (
          <div className="flex flex-col inset-0 fixed z-30 bg-white items-center">
            <div className="flex justify-between pt-4 px-4 items-center">
              <div className="text text-lg">{props.name}</div>
              <XIcon
                onClick={() => setShowPreview(false)}
                className="w-6 h-6 ml-6"
              />
            </div>
            <img
              src={props.url}
              className="flex-1 object-contain max-w-full lg:max-w-lg"
            />
          </div>
        )} */}

      {/* {showPreview && extension === "smallvid.png" && (
        <div className="flex flex-col inset-0 fixed z-30 bg-white items-center">
          <div className="flex justify-between pt-4 px-4 items-center ">
            <div className="text text-lg">{props.name}</div>
            <XIcon
              onClick={() => setShowPreview(false)}
              className="w-6 h-6 ml-6"
            />
          </div>
          <video
            controls
            src={props.url}
            className="flex-1 object-contain max-w-full lg:max-w-lg"
          />
        </div>
      )} */}

      {showPreview && props.type && props.type === "QUICKFLOW" && (
          <div className="flex fixed z-20 pt-16 inset-0 max-w-screen-sm m-auto bg-white text-black">
          <QuickFlowModal refDocId={props.id} closePreview={setShowPreview} />

          </div>
      )}
       {showPreview && props.type && props.type === "CUSTOM" && (
        
          <div className="flex fixed z-20 pt-16 inset-0 max-w-screen-sm m-auto bg-white text-black">
          <CustomRefdocModal refDocId={props.id} closePreview={setShowPreview} />

          
        </div>
      )}

      <div onClick={() => emitRefdocRedEvent(props.id || 0)} key={id} className={classNames("refdoc-item", props.type === "QUICKFLOW" ? "-ml-1.5" : "")}>
        <img
          className={
            props.type && props.type === "QUICKFLOW" ? "h-10 w-10" : "h-8 w-8"
          }
          style={{ objectFit: "contain" }}
          src={
            props.type && props.type === "QUICKFLOW"
              ? "https://cdn.iconscout.com/icon/free/png-256/electric-showk-electrical-electricity-shock-wire-38949.png"
              : file_extension_url + extension &&
                props.type &&
                props.type === "CUSTOM"
              ? "https://cdn4.iconfinder.com/data/icons/free-colorful-icons/360/google_docs.png"
              : file_extension_url + extension
          }
        />
        <div style={{ padding: 10, fontSize: 15 }}>{props.name} {!!showCount && count}</div>
      </div>
    </a>
  );
};
