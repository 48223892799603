import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventBus } from "../../event-bus/event-bus";
import { QuickFlowModal } from "./quickflow-modal";

export const QuickFlow = (props: any) => {
  const params = useParams();
  const document_id = +params.id!;
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      eventBus.dispatch("USER_EVENT", {
        event: "REFERENCE_DOCUMENT_READ",
        details: {
          object_id: document_id,
        },
      });
    }, 1500);
  }, []);
  return <QuickFlowModal closePreview={() => navigate("/home")} refDocId={document_id} />
};
