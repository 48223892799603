import React, { useState } from "react";
import "./login.scss";
import { gql, useMutation } from "@apollo/client";
// import history from "../../history";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import bgimage from "../../images/login_image1.jpg";
import { Button, Modal, notification } from "antd";
import { CONSTANTS } from "../../constants";

const IS_ROLLEASE = window.location.host.indexOf("rollease") > -1;

const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      accessToken
    }
  }
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      error
      message
    }
  }
`;

interface LoginDataResponse {
  login: {
    accessToken: string;
  };
}

interface ForgotPasswordDataResponse {
  forgotPassword: {
    error: boolean;
    message: string;
  };
}

export const LoginScreen = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get("redirectTo");

  const [loginUser, { error, loading, data }] = useMutation<LoginDataResponse>(
    LOGIN_MUTATION,
    {
      update: (cache, response) => {
        localStorage.setItem("token", response.data?.login.accessToken!);
        if(redirectTo) {
          navigate(redirectTo);
        } else {
          navigate("/home");
        }
      },
    }
  );
  const [forgotPassword] = useMutation<ForgotPasswordDataResponse>(
    FORGOT_PASSWORD_MUTATION,
    {
      update: (response, result) => {
        if (result.data?.forgotPassword.error) {
          notification.error({
            message: result.data?.forgotPassword.message,
          });
        } else {
          notification.success({
            message: result.data?.forgotPassword.message,
          });
        }
        setEmail("");
        setForgotPasswordModalVisible(false);
      },
    }
  );
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(
    false
  );
  const [didLoginError, setLoginDidError] = useState(false);

  if (data?.login.accessToken) {
    return <Navigate to="/home" />;
  }

  return (
    <div
      className="bg-white flex login-screen-container w-full absolute inset-0"
    >
      {IS_ROLLEASE && <div className="fixed bg-gray-100 z-10 p-4 w-full left-0 right-0 flex top-0 items-center logo-icon text-xl sm:text-2xl" style={{ color: "#055b7f" }}>
        MINDSTRENGTH
        {IS_ROLLEASE && <>
          <div className="px-2 text-lg lg:px-3" style={{
            fontFamily: "'Allura', cursive",
          }}>for</div>
          <img alt="Rollease" src="https://www.rolleaseacmeda.com/app_themes/landing/images/rolleaseacmeda.png" className="h-6 sm:h-6" />
        </>}
      </div>}
      <div className="flex-1 flex flex-col justify-center pb-12 lg:flex-none bg-white login-form-container">
        <div className="mx-auto w-full px-6">
          <div>
            {!IS_ROLLEASE && <div className="flex flex-col items-start logo-icon text-xl sm:text-2xl" style={{ color: "#055b7f" }}>
              MINDSTRENGTH
            </div>}
            <h2 className="mt-6 text-lg font-bold text-gray-600">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email address"
                      value={username}
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(evt) => {
                        setUsername(evt.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      placeholder="Password"
                      value={password}
                      onChange={(evt) => {
                        setPassword(evt.target.value);
                      }}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <div className="text-sm">
                    <a
                      onClick={() => setForgotPasswordModalVisible(true)}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    onClick={(evt) => {
                      evt.preventDefault();
                      loginUser({
                        variables: {
                          username,
                          password,
                        },
                      }).catch((err) => {
                        console.log("Got Error: ", err);
                        setLoginDidError(true);
                      });
                    }}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign in
                  </button>
                </div>
              </form>
              <br />
              <div>
                {didLoginError && (
                  <div className="bg-blue-200 rounded-md p-2 pl-5">
                    {error?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="h-full object-cover"
          // src="https://images.squarespace-cdn.com/content/v1/5cac2e5b0490794ccd955143/1612039278170-5U3Z6GTPW9KK6HVLP4RN/background.jpg?format=2500w"
          src="https://d205v7va10brr8.cloudfront.net/2ab21ed8-0fd3-46c0-a681-67462b2bfbce.jpg"
        />
      </div>
      <Modal
        visible={forgotPasswordModalVisible}
        title="Reset Password"
        onCancel={() => {
          setEmail("");
          setForgotPasswordModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setEmail("");
              setForgotPasswordModalVisible(false);
            }}
          >
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={(evt) => {
              evt.preventDefault();
              forgotPassword({
                variables: {
                  email: email.toLowerCase(),
                },
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="space-y-1">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              required
              placeholder="Please enter your email address"
              value={email}
              onChange={(evt) => {
                setEmail(evt.target.value);
              }}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
