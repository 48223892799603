import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GetInitiativeSectionOutput, InitiativeWithProgressOutput, LearnerDetailsOutput, UnitEntity,
} from "../../generated/graphql";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { useParams } from "react-router-dom";
import { InitiativeIntro } from "../home/initiative-intro/initiative-intro";

interface LearnerInitiativesData {
  initiativeFromChapter: UnitEntity;
  getLearnerDetails: LearnerDetailsOutput;
}

export const InitiativeIntroPreviewScreen = (props: any) => {
  const params = useParams();
  const initiativeId = +(params.id ?? "0");

  const GET_INITIATIVES = gql`
  query Intro {
    initiativeFromChapter(chapter_id: ${initiativeId}) {
      id
      name
      parent_unit
      is_private
      reassigned_display_name
      intro
    }

    getLearnerDetails {
      id
      first_name
      last_name
      user_id_state
      is_doctor
    }
  }
`;

  const { loading, error, data, refetch } = useQuery<LearnerInitiativesData>(
    GET_INITIATIVES,
    {
      variables: { user_id: 1 },
      fetchPolicy: "no-cache"
    }
  );

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <div className="flex-1" >
      <InitiativeIntro
        learnerDetails={data?.getLearnerDetails!}
        initiative={data?.initiativeFromChapter!}
        isPreview={true}
      />
    </div>
  )
};
