import React, { useState } from "react";
import {
  LearnerDetailsOutput,
  QuestionEntity,
} from "../../../../generated/graphql";
import { TypeText } from "../../../type-text/type-text";
import "./question-starter.scss";

interface Props {
  onQuestionStarterComplete: Function;
  previousQuestionId: number;
  question: QuestionEntity;
  learnerDetails: LearnerDetailsOutput;
  isPreview?: boolean;
}

export const QuestionStarter = (props: Props) => {
  const getRandomCorrectMessage = () => {
    const messages = [
      `Let's move ahead with our discussion.`,
      `Let's continue our discussion.`,
      `There is more to discuss.`,
      `Alright, let’s get into the discussion.`,
      `Now that we have that context, let's discuss.`,

    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };

  const getRandomIncorrectMessage = () => {
    const first_name = props.learnerDetails.first_name;
    const last_name = props.learnerDetails.last_name;
    const is_doctor = props.learnerDetails.is_doctor;
    const name = is_doctor ? `Dr. ${last_name}` : first_name;
    const messages = [
      `Let's try that again!`,
      `All right, let's try this again!`,
      `Let's give it another try, ${name}.`,
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };

  const [starterLanguage, setStarterLanguage] = useState(
    (props.previousQuestionId === props.question.id && !props.isPreview)
      ? getRandomIncorrectMessage()
      : getRandomCorrectMessage()
  );
  return (
    <div className="mentor-communication">
      <TypeText isPreview={props.isPreview} onComplete={props.onQuestionStarterComplete}>
        {starterLanguage}
      </TypeText>
    </div>
  );
};
