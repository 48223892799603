import React, { useState } from "react";
import "./login.scss";
import {Redirect, useParams} from "react-router-dom";
import bgimage from '../../images/login_image.jpeg'
import {CONSTANTS} from "../../constants";
import {Button, notification} from "antd";
import {useNavigate} from "react-router";

export const ResetPasswordScreen = () => {
  let { token } = useParams<any>();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="logo-icon">MINDSTRENGTH</div>
              <h2 className="mt-6 text-xl font-bold text-gray-600">Reset your password</h2>

            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6">
                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          placeholder="Password"
                          value={password}
                          onChange={(evt) => {
                            setPassword(evt.target.value);
                          }}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(evt) => {
                            setConfirmPassword(evt.target.value);
                          }}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <Button key="submit" type="primary"
                        disabled={password !== confirmPassword || !password || !confirmPassword}
                        onClick={(evt) => {
                            evt.preventDefault()
                            const requestOptions = {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    token,
                                    password
                                }),
                            };
                            fetch(
                                `${CONSTANTS.SAILS_BACKEND_URL}/auth/resetPasswordUsingToken`,
                                requestOptions
                            ).then((response) => {
                                response.json().then(r => {
                                    setConfirmPassword("");
                                    setPassword("");
                                    if (r.error) {
                                        notification.error({
                                            message: r.message,
                                        });
                                    } else {
                                        navigate('/login')
                                        notification.success({
                                            message: r.message,
                                        });
                                    }
                                });
                            });
                        }}
                        className="w-full"
                    >
                      Reset
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:block relative w-0 flex-1">
          <img
              className="absolute inset-0 h-full w-full object-cover"
              src={bgimage}
              alt=""
          />
        </div>
      </div>
  );
};
