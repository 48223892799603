import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomRefdocModal } from "./custom-refdoc-modal";

export const CustomRefdocNoHeader = (props: any) => {
  const params = useParams();
  const document_id = +params.id!;
  const navigate = useNavigate();

  return (
    <div className="flex overflow-auto">
        <CustomRefdocModal hideClose={true} hideShare={true} closePreview={() => navigate("/home") } refDocId={document_id}  />
    </div>
  )
};
