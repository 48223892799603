import React, { useRef, useState } from "react";
import { TypeText } from "../../../components/type-text/type-text";
import { gql, useMutation } from "@apollo/client";
import { MstrButton } from "../../../components/mstr-button/mstr-button";
import { IhAssistOutput, ReferenceDocumentEntity } from "../../../generated/graphql";
import { RefdocItem } from "../../../components/refdoc-item/refdoc-item";
import { StaggerList } from "../../../components/stagger-list/stagger-list";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { MsLoader } from "../../../components/header/screens/ms-loader/ms-loader";
import { FadeIn } from "../../../components/fade-in/fade-in";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";

const ERROR_MESSAGES = [
    "We ran into an issue while processing your request. Our team is on it. Want to try again?",
    "Something didn’t go as expected. We’re investigating the issue. Would you like to retry?",
    "Hmm... something went wrong. Can you please rephrase your request?",
    "Sorry. Something went wrong. Can you please rephrase your request?",

];

const WAIT_MESSAGES = [
    "Got it. Working on it...",
    "Thanks...Processing. Analyzing...",
    "Understanding. Processing...",
    "Analyzing. Putting everything together...",
];

const WAIT_LONGER_MESSAGES = [
    "Thanks for your patience. We will have a response for you shortly.",
    "Great request. We’re working on it and will have something soon.",
    "This is taking a little longer than expected. We are putting together the perfect response.",
    "We’re taking some extra time to ensure we provide the most accurate answer.",
];

const AMBIGUOUS_MESSAGES = [
    "Thanks for the request. Can you please revise your request to be more specific.",
    "Sorry, but we need more specifics. Can you please rephrase your request?",
];

const getRandomLanguage = () => {
    return WAIT_MESSAGES[Math.floor(Math.random() * WAIT_MESSAGES.length)];
};

const getRandomErrorLanguage = () => {
    return ERROR_MESSAGES[Math.floor(Math.random() * ERROR_MESSAGES.length)];
};

const getRandomLongerWaitLanguage = () => {
    return WAIT_LONGER_MESSAGES[Math.floor(Math.random() * WAIT_LONGER_MESSAGES.length)];
};

const getRandomAmbiguousLanguage = () => {
    return AMBIGUOUS_MESSAGES[Math.floor(Math.random() * AMBIGUOUS_MESSAGES.length)];
};

// const SHOULD_SHOW_ASSISTANT_CHOICES = IS_LOCALHOST || IS_WINDOW;
const SHOULD_SHOW_ASSISTANT_CHOICES = false;

const assistants = [
    {
        id: "asst_rLLQCp5W2COX6wjHcKpLF7XC",
        name: "MS Project Disclosure",
        label: "Costco: Project Disclosures 2024",
    },
    {
        id: "asst_mWh6VTwSIRZ9cHaHr7v6shcs",
        name: "MS Table Query",
        label: "Graber Pricing",
    },
    {
        id: "asst_ZBm624UE0u0j3PjHaEAwkjUZ",
        name: "MS PDF Query",
        label: "Graber Price Group",
    },
  ];

const GET_ASSIST_DATA = gql`
  mutation IhAssist($message: String!, $asst_id: String!) {
    ihAssist(message: $message, asst_id: $asst_id) {
        documents {
            id
            document_name
            url
            reftype
        }
        initiative {
            id
            name
        }
        answer
        is_ambiguous
    }
  }
`;

const SELF_ASSIGN_INITIATIVE = gql`
  mutation SelfAssignInitiativeToLearner($initiative_id: Int!) {
    selfAssignInitiativeToLearner(initiative_id: $initiative_id)
  }
`;

type iProps = {
    assignedInitiativeIds: number[];
    refetchInitiatives: () => Promise<void>;
    toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InlineAssistant = (props: iProps) => {

    const [connectorLanguage, setConnectorLanguage] = useState(getRandomLanguage());
    const [errorConnectorLanguage, setErrorConnectorLanguage] = useState(getRandomErrorLanguage());
    const [connectorWaitLongerLanguage, setConnectorWaitLongerLanguage] = useState(getRandomLongerWaitLanguage());
    const [ambiguousConnectorLanguage, setAmbiguousConnectorLanguage] = useState(getRandomAmbiguousLanguage());

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showLongerMessage, setShowLongerMessage] = useState(false);


    const [showTextbox, setShowTextBox] = useState(false);
    const [message, setMessage] = useState("");
    const [answerTyped, setAnswerTyped] = useState(false);
    const [refdocHeadingTyped, setrefdocHeadingTyped] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showInitiativeDetail, setShowInitiativeDetail] = useState(false);


    const [showAssistantChoices, setShowAssistantChoices] = useState(false);
    const [selectedAssistantIndex, setSelectedAssistantIndex] = useState(0);

    const cancelRef = useRef<any>();

    const setMessageChangeTimeout = () => {
        // console.log("setting up the timer");
        // const cancalLongermessage = useTimeout(() => {
        //     console.log("5 seconds passed, show next message");
        //     setShowLongerMessage(true);
        // }, 5000);

        // cancelRef.current = cancalLongermessage;
    }

    const assistHandler = () => {

        setShowErrorMessage(false);

        let messageToSend = message;

        if(showAssistantChoices) {

            // 1 "Costco: Project Disclosures 2024" (As it is, no change in message)
            if (selectedAssistantIndex === 0) {
                messageToSend = message;
            }
            
            // 2 "Graber Pricing" (As it is, no change in message)
            if (selectedAssistantIndex === 1) {
                messageToSend = message;
            }
            
            // 3 "Graber Price Group"
            if (selectedAssistantIndex === 2) {
                messageToSend = `In the given data, Product color may be present in multiple locations. When asked about the price group of specific product colors, please list every Price group where color is found, ensuring that no locations are omitted. {question}: '${message}'`;
            }
        }

        const messageTimeout = setTimeout(() => {
            setShowLongerMessage(true);
        }, 7000);

        assist({
            variables: {
                message: messageToSend,
                asst_id: showAssistantChoices ? assistants[selectedAssistantIndex].id : "NA",
            }
        }).catch(() => {
            console.log("Catch for Assist");
            setShowErrorMessage(true);
        })
        .finally(() => {
            clearTimeout(messageTimeout);
        });

        setMessageChangeTimeout();
    }

    const resetAssistant = () => {
        setResponse(undefined);
        setShowTextBox(false);
        setMessage("");
        setAnswerTyped(false);
        setrefdocHeadingTyped(false);
        setShowButton(false);
        setShowInitiativeDetail(false);
        setShowErrorMessage(false);
    }

    const [selfAssignInitiative, { loading: loadingAssignInitiative }] = useMutation(
        SELF_ASSIGN_INITIATIVE,
        {
            update: async (cache, res) => {
                console.log("Got self assign initiative response: ", response);
                if(cancelRef.current) {
                    console.log("Cancelling timeout after getting API response");
                    cancelRef.current();
                }
                await props.refetchInitiatives();
                if (response) {
                    setTimeout(() => {
                        if(response && response.initiative) {
                            navigate(`/initiative-intro/${response.initiative.id}`);
                        }
                    }, 1000);
                }
            },
        }
    );

    const navigate = useNavigate();

    const [response, setResponse] = useState<IhAssistOutput>();

    const [assist, { loading }] = useMutation(GET_ASSIST_DATA, {
        update: (cache, response) => {
            console.log("Assist Response: ", response);
            setShowTextBox(false);
            setResponse(response.data.ihAssist);
            setShowLongerMessage(false);
            setConnectorLanguage(getRandomLanguage());
            setConnectorWaitLongerLanguage(getRandomLongerWaitLanguage());
            setErrorConnectorLanguage(getRandomErrorLanguage());
            setAmbiguousConnectorLanguage(getRandomAmbiguousLanguage());

            if(response.data.ihAssist.is_ambiguous) {
                setShowTextBox(true);
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        },
    });

    const isInitiativeAssigned = response && response.initiative && props.assignedInitiativeIds.indexOf(+response.initiative.id) > -1 ? true : false;

    return (
        <div className="pb-4">

            {loadingAssignInitiative && <div className="fixed inset-0 flex items-center justify-center bg-white">
                <MsLoader />
            </div>}

            <div className="flex items-center">
                <div className="flex-1">
                    {!response && <TypeText delay={500} onComplete={() => {
                        setShowTextBox(true);
                    }}>
                        Need help or looking for a document?
                    </TypeText>}
                </div>
                {SHOULD_SHOW_ASSISTANT_CHOICES && <div className="text-blue-500 text-lg flex items-center" onClick={() => setShowAssistantChoices(!showAssistantChoices)}>
                    more
                    <ChevronDownIcon className="h-5 w-5" />
                </div>}
            </div>

            {showAssistantChoices && <div className="space-y-3 py-3 text-base">
                {assistants.map((assistant, index) => (
                    <div className="flex items-center space-x-2" onClick={() => setSelectedAssistantIndex(index)}>
                        <CheckIcon className={classNames(
                            "h-5 w-5 text-blue-500",
                            index === selectedAssistantIndex ? "" : "opacity-0"
                        )} />
                        <div>
                            {assistant.label}
                        </div>
                    </div>
                ))}
            </div>}

            {showTextbox && <motion.textarea
                animate={{ height: "auto" }}
                initial={{
                    height: "1px",
                }}
                transition={{
                    duration: 0.6,
                }}
                onAnimationComplete={() => {
                    setShowButton(true);
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full my-4 outline-none rounded-md"
                autoFocus={true}
                rows={2}
            />}

            {response && <div>
                <div className="text-base">

                    {response.is_ambiguous && <TypeText onComplete={() => {
                        
                    }}>
                        {ambiguousConnectorLanguage}
                    </TypeText>}

                    {!response.is_ambiguous && <TypeText onComplete={() => {
                        setAnswerTyped(true);
                    }}>
                        {response.answer}
                    </TypeText>}
                </div>

                {response.documents && answerTyped && <div>

                    {response.documents.length > 0 && <TypeText onComplete={() => setrefdocHeadingTyped(true)}>
                        <div className="text-base pt-6">
                            {response.documents.length === 1 ?
                                "Here is a QuickFlow (or document) that will help:" : "Here are some QuickFlows (or documents) that will help:"
                            }
                        </div>
                    </TypeText>}

                    {response.documents.length === 0 && <TypeText onComplete={() => setShowInitiativeDetail(true)}>
                        <div className="text-base">
                        </div>
                    </TypeText>}

                    {refdocHeadingTyped && <StaggerList
                        onAnimationComplete={() => {
                            setShowInitiativeDetail(true);
                        }}
                        list={response.documents.map((document: ReferenceDocumentEntity) => (
                            <div style={{ paddingLeft: 10 }} key={document.id}>
                                <RefdocItem
                                    refdocCount={{}}
                                    id={document.id}
                                    name={document.document_name}
                                    url={document.url}
                                    type={document.reftype}
                                />
                            </div>
                        ))}
                    />}

                    <br />

                </div>}

                {showInitiativeDetail && <div className="text-base">
                    {response.initiative && <div>
                        <TypeText onComplete={() => {
                            setShowButton(true);
                        }}>
                            To get an even better understanding, try this initiative, <span className="font-bold text-blue-500 cursor-pointer" onClick={() => {
                                const isAlreadyAssigned = props.assignedInitiativeIds.indexOf(+(response.initiative?.id || "0")) > -1;

                                if (isAlreadyAssigned) {
                                    if(response && response.initiative) {
                                        navigate(`/initiative-intro/${response.initiative.id}`);
                                    }
                                } else {
                                    selfAssignInitiative({
                                        variables: {
                                            initiative_id: +(response.initiative?.id || "0"),
                                        }
                                    })
                                }
                            }}>{response.initiative.name}.</span>
                            <br />
                        </TypeText>
                    </div>}
                    {!response.initiative && <div>
                        <TypeText onComplete={() => {
                            setShowButton(true);
                        }}>
                            &nbsp;
                        </TypeText>
                    </div>}
                </div>}
            </div>}

            {showErrorMessage && <>
                {<div className="assist-connector text-base pb-2">
                        {<TypeText delay={500} onComplete={() => {
                            
                        }}>
                            {errorConnectorLanguage}
                        </TypeText>}
                    </div>}

                    <MstrButton disabled={loading} showLoading={loading} text={"Try again"} onClick={() => {
                        assistHandler();
                    }} />
            </>}


            {!showErrorMessage && showButton && <FadeIn>
                {!response && 
                <>
                    {loading && <div className="assist-connector text-base pb-2">
                        {showLongerMessage && <TypeText delay={500} onComplete={() => {
                            
                        }}>
                            {connectorWaitLongerLanguage}
                        </TypeText>}

                        {!showLongerMessage && <TypeText delay={500} onComplete={() => {
                            
                        }}>
                            {connectorLanguage}
                        </TypeText>}
                    </div>}

                    <MstrButton disabled={loading} showLoading={loading} text={"Go"} onClick={() => {
                        assistHandler();
                    }} />
                </>
            }

                {response && <div className="flex space-x-6 mt-6">
                    <MstrButton className="flex-1" text="New Request" onClick={() => {
                        resetAssistant();
                    }} />

                    <MstrButton className="flex-1" text="All Done" onClick={() => {
                        props.toggle(false);
                    }} />
                </div>}
            </FadeIn>}

        </div>
    )
}