import React, { useEffect, useState } from "react";
import "./initiate-conversation.scss";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ChatConversationEntity,
  ConversationsListOutput,
  InitiativeWithProgressOutput,
} from "../../../../../../generated/graphql";
import {
  ArrowRightIcon,
  ArrowSmRightIcon,
  ArrowNarrowRightIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router";
import { eventBus } from "../../../../../../event-bus/event-bus";
import { Drawer } from "antd";
import { ConversationDetails } from "../conversation-details/conversation-details";
import { MsLoader } from "../../../ms-loader/ms-loader";
import { InitiativeConversationWithUser } from "../initiative-conversation-with-user/initiative-conversation-with-user";

const tabs = [
  { name: "Initiative", mode: "INITIATIVE", current: true },
  { name: "User", mode: "USER", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const token = localStorage.getItem("token");
let userId: number;

if (token) {
  const details = JSON.parse(atob(token!.split(".")[1]));
  userId = details.userId;
} else {
  userId = 0;
}

const isMobile = window.innerWidth < 550;

interface InitiativesData {
  getInitiativesForLearnerWithProgress: InitiativeWithProgressOutput[];
  getConversations: ConversationsListOutput[];
}

const GET_INITIATIVES = gql`
  query GetInitiativesForConversation {
    getInitiativesForLearnerWithProgress {
      unit {
        id
        name
        is_private
      }
    }
    getConversations(initiative_id: 0) {
      conversation {
        id
        initiative {
          id
        }
      }
    }
  }
`;

const INITIATE_CONVERSATION = gql`
  mutation InitiateConversation($initiative_id: Int!, $section_id: Int!) {
    initiateConversation(
      initiative_id: $initiative_id
      section_id: $section_id
    ) {
      id
      section_id
      initiative_id
    }
  }
`;

interface Props {
  closeInitiateConversationDrawer?: Function;
  buttonRef?: any;
}

export const InitiateConversation = (props: Props) => {
  const [mode, setMode] = useState("INITIATIVE");
  const { loading, error, data, refetch } = useQuery<InitiativesData>(
    GET_INITIATIVES
  );

  const [selectedConversationId, setSelectedConversationId] = useState(0);

  const navigate = useNavigate();

  const [initiateConversation] = useMutation(INITIATE_CONVERSATION, {
    update: (cache, response) => {
      console.log("Initiate Response: ", response);
      if (isMobile) {
        navigate(`/conversations/${response.data.initiateConversation.id}`);
      } else {
        eventBus.dispatch("CHAT_CONVERSATION_SELECTED", {
          conversation_id: response.data.initiateConversation.id,
        });
      }
    },
  });

  const handleInitiateConversationFromHeader = (details: {
    initiative_id: any;
    section_id: any;
  }) => {
    const { initiative_id, section_id } = details;
    console.log("Header Conversation: ", initiative_id, section_id);
    initiateConversation({
      variables: {
        initiative_id,
        section_id,
      },
    });
  };

  useEffect(() => {
    eventBus.on(
      "INITIATE_CONVERSATION_FROM_HEADER",
      handleInitiateConversationFromHeader
    );
    return () => {
      eventBus.remove(
        "INITIATE_CONVERSATION_FROM_HEADER",
        handleInitiateConversationFromHeader
      );
    };
  }, []);

  if (loading) {
    return <MsLoader />;
  }

  const existingConversations: string[] = [];
  data?.getConversations.forEach((c) => {
    if (c.conversation.initiative) {
      existingConversations.push(c.conversation.initiative.id);
    }
  });

  const initiatives = data?.getInitiativesForLearnerWithProgress.filter(
    (i) => i.unit.is_private !== 1
  );

  return (
    <div className="initiatives-list">
      <div>
        <div className="px-2 pt-4" style={{ fontSize: 14 }}>
          Select an initiatives to start a conversation with the{" "}
          <b>initiative lead</b>.
        </div>
        {initiatives?.map((initiative) => {
          // if (
          //   existingConversations?.indexOf(initiative.unit.id) > -1
          // ) {
          //   return [];
          // }
          return (
            <div
              onClick={() => {
                console.log("INITIATE CONVERSATION 1");
                initiateConversation({
                  variables: {
                    initiative_id: +initiative.unit.id,
                    section_id: 0,
                  },
                });
                if (props.buttonRef) {
                  props.buttonRef.current?.click();
                }
              }}
              key={initiative.unit.id}
              className="chat-initiative-item flex items-center justify-start rounded-md mt-2 cursor-pointer"
            >
              <ArrowRightIcon className="w-4 h-4 text-gray-500" />
              <div className="text-sm flex-1 pl-2">{initiative.unit.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
